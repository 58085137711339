import './App.css';

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchBox from './SearchBox';
import SearchResult from './SearchResult';

const App = () => {

    return (
        <div className="search" style={{ 
            marginTop:'20%',
            marginLeft:'10%',
            width: '30%',
            }}>
            <div style={{
                textAlign: 'center'
                }}>
                <h2>  Track Your Order From Viana Care</h2>
                <p>Get updates about your order status. Please enter your order ID or tracking number.
                </p>
            </div>

            <SearchBox/>

            <br/>

            <SearchResult/>
        </div>
    );
}

export default App
