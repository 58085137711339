import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import { view } from '@risingstack/react-easy-state';
import appStore from './appStore';

const RedTextTypography = withStyles({
  root: {
    color: "#FF0000"
  }
})(Typography);

const SearchResult = () => (

  <div className="searchresult">
      { appStore.searchFound && <div className="seachResult">
          <Stepper activeStep={appStore.searchResult.activeStep} orientation="vertical">
              { appStore.searchResult.steps.map((label, index) => (
                  <Step key={label}>
                      <StepLabel className={appStore.searchResult.useStyles.stepLabel}>{label}</StepLabel>
                      <StepContent>
                          {appStore.searchResult.statusDetails && 
                            <Typography className={appStore.searchResult.useStyles.stepContent}>
                            {appStore.searchResult.statusDetails}
                          </Typography>}
                          {appStore.searchResult.locationDetails &&
                            <Typography className={appStore.searchResult.useStyles.stepContent}>
                            {appStore.searchResult.locationDetails}
                          </Typography>}
                          <Typography className={appStore.searchResult.useStyles.stepContent}>
                            <small>
                            {appStore.searchResult.updateTime}
                            </small>
                          </Typography>
                      </StepContent>
                  </Step>
              ))}
          </Stepper>
          {appStore.searchResult.activeStep === appStore.searchResult.steps.length && (
              <Paper square elevation={0} className={appStore.searchResult.useStyles.resetContainer}>
                  <Typography>Your order has been delivered!</Typography>
              </Paper>
          )}
      </div>}

      { appStore.searchError && 
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <RedTextTypography variant="h6">
              { appStore.searchError  }
            </RedTextTypography>
          </div>
      }
  </div>
);

export default view(SearchResult);
