import React from 'react';
import { view } from '@risingstack/react-easy-state';
import SearchBar from 'material-ui-search-bar';
import LinearProgress from '@material-ui/core/LinearProgress';
import appStore from './appStore';

// this is re-rendered whenever the relevant parts of the used data stores change
const SearchBox = () => (
  <div className="searchbar">
    <SearchBar
      onRequestSearch={appStore.search}
      placeholder="order or tracking number"
      autoFocus
    />
    {appStore.isLoading && <LinearProgress />}
  </div>
);

export default view(SearchBox);

