import { store } from '@risingstack/react-easy-state';
import { makeStyles } from '@material-ui/core/styles';
import * as api from './api';

var timeDisplayOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

function utcTimeToLocal(s) {
  if (s.includes("+")) {
    return new Date(s).toLocaleString(undefined, timeDisplayOptions)
  }
  return new Date(s + "+0000").toLocaleString(undefined, timeDisplayOptions)
}

// use 'appStore' instead of 'this' in the store methods to make them passable as callbacks
const appStore = store({
  async search(filter) {
    console.log("searching", filter);
    appStore.isLoading = true;
    appStore.searchFound = false;
    appStore.searchError = null;

    // do some http call here to get result
    let orders = await api.fetchOrders(filter);
    appStore.isLoading = false;
    console.log(orders)
    if (typeof orders === 'string' || orders instanceof String) {
        appStore.searchFound = false;
        appStore.searchError = orders;
        return;
    }
    orders = orders.data
    switch(orders.status) {
    case "processing" :
    case "labeled" :
        appStore.searchResult.activeStep = 1;
        break;
    case "transit" :
    case "clearance" :
    case "delay" :
        appStore.searchResult.activeStep = 2;
        break;
    case "delivered" :
        appStore.searchResult.activeStep = 3;
        break;
    }
    appStore.searchResult.updateTime = utcTimeToLocal(orders.update_time);
    appStore.searchResult.statusDetails = "detailed_description" in orders ? orders.detailed_description : null;
    appStore.searchResult.locationDetails = "location" in orders ? orders.location : null;
    appStore.searchFound = true;
  },
  searchResult : {
    useStyles : makeStyles((theme) => ({
      stepLabel: {
          '& > *': {
                fontWeight: 600,
          }
      },
      stepContent: {
          color: 'gray',
          fontSize: 12
      }
    })),
    activeStep : 2,
    steps : ['Order received', 'Labeled', 'In transit', 'Delivered'],
    updateTime : "none",
    statusDetails : null,
    locationDetails : null,
  }
});

export default appStore;

