import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.titantools.org/d31/vianacare'
});

export async function fetchOrders(filter) {
  const data = await api.post('/searchorder', {
    search_term : filter })
    .then((resp) => { return resp; })
    .catch((err) => { 
        let d = err.response.data;
        if (typeof d === 'string' || d instanceof String) {
            return d;
        }
        return d.message; })
  return data;
}
